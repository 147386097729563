(function ($) {
	"use strict";

	$(window).load(function () {


		var $testimonials_content = $('.thim-testimonials'),
			$testimonials_img = $('.thim-testimonial-custom-dots'),
			duration = 300,
			thumbs = 2;

		var auto = parseInt($testimonials_content.attr('data-auto')),
			number_testimonial = $testimonials_content.attr('data-testimonials');


		var animation_layout_2 = $testimonials_content.attr('data-animation');


// Sync nav
		$testimonials_content.on('click', '.owl-next', function () {
			$testimonials_img.trigger('next.owl.carousel')
		});
		$testimonials_content.on('click', '.owl-prev', function () {
			$testimonials_img.trigger('prev.owl.carousel')
		});

// Start Carousel
		if (auto == 0) {
			$testimonials_content.owlCarousel({
				center   : true,
				loop     : true,
				items    : 1,
				margin   : 0,
				dots     : false,
				mouseDrag: false,
				touchDrag: false,
				animateIn: animation_layout_2,
			});

			$testimonials_img.owlCarousel({
				center       : true,
				loop         : true,
				items        : 3,
				dots         : false,
				autoWidth    : true,
				mouseDrag    : false,
				startPosition: 0,
			})
				.on('click', '.owl-item', function () {
					var i = $(this).index() - (thumbs + 1);
					$testimonials_img.trigger('to.owl.carousel', [i, duration, true]);
					$testimonials_content.trigger('to.owl.carousel', [i, duration, true]);

					if (number_testimonial <= 3) {
						if ($('.thim-testimonial-custom-dots .owl-item').eq(2).hasClass('center')) {
							$('.thim-testimonial-custom-dots .owl-stage-outer').css('padding-left', '5px');
						} else {
							$('.thim-testimonial-custom-dots .owl-stage-outer').css('padding-left', '12px');
						}
						;
					}

				});
		} else {
		}

		$testimonials_img.on('refreshed.owl.carousel', function (e) {
			if (number_testimonial <= 3) {
				$('.thim-testimonial-custom-dots .owl-stage-outer').css('padding-left', '12px');
			}

			var idx = e.item.index;
			$('.thim-testimonial-custom-dots .owl-item').eq(idx - 1).addClass('item-left active');
		});


		$testimonials_img.on('changed.owl.carousel', function (e) {
			var idx = e.item.index;
			$('.thim-testimonial-custom-dots .owl-item.item-left').removeClass('item-left');
			$('.thim-testimonial-custom-dots .owl-item.item-right').removeClass('item-right');

			$('.thim-testimonial-custom-dots .owl-item').eq(idx - 1).addClass('item-left');
			$('.thim-testimonial-custom-dots .owl-item').eq(idx + 1).addClass('item-right');


		});


		// For default layout

		var $testimonial = $(".testimonial-slider-center");
		var $testimonial_left = $(".testimonial-slider-left");

		var $animation = $(".thim-sc-testimonial").attr("data-animation");

		var center_layout = 'zoomOutLeft',
			left_layout = 'slideInDown';

		if ($animation != 'no') {
			center_layout = left_layout = $animation;
		}

		var css_animation = $testimonial.attr('data-animation');
		var auto2 = $('.thim-sc-testimonial').attr('data-auto');

		if ($testimonial.length) {
			if (auto2 !== 'false') {
				$testimonial.owlCarousel({
					items    : 1,
					dots     : true,
					mouseDrag: true,
					touchDrag: false,
					animateIn: center_layout,
					autoplay : true,
					loop     : true
				});
			} else {
				$testimonial.owlCarousel({
					items    : 1,
					dots     : true,
					mouseDrag: true,
					touchDrag: false,
					animateIn: center_layout
				});
			}
		}

		if ($testimonial_left.length) {
			if (auto2 !== 'false') {
				$testimonial_left.owlCarousel({
					items          : 1,
					dots           : true,
					mouseDrag      : true,
					touchDrag      : false,
					animateIn      : left_layout,
					autoplay       : true,
					loop           : true,
					autoplayTimeout: 20000
				});
			} else {
				$testimonial_left.owlCarousel({
					items    : 1,
					dots     : true,
					mouseDrag: true,
					touchDrag: false,
					animateIn: left_layout
				});
			}
		}
	});

})(jQuery);