(function ($) {
	"use strict";
	$(document).ready(function () {
		var location = $("#thim-weather").attr("data-location");
		var lat = $("#thim-weather").attr("data-lat");
		var lng = $("#thim-weather").attr("data-lng");
		var unit = $("#thim-weather").attr("data-unit");
		var api = $("#thim-weather").attr("data-api");
		var url = $("#thim-weather").attr("data-url");

		$('.weather-temperature').openWeather({
			key        : api,
			city       : location,
			lat        : lat,
			lng        : lng,
			iconTarget : '.weather-icon',
			units      : unit,
			customIcons: url + 'weather/assets/images/icons/weather/',
			success    : function (data) {
				$('.weather-wrapper').show();
			},
			error      : function (data) {
				$('.weather-wrapper').remove();
			}
		});
	});

})(jQuery);

